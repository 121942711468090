import React, { useEffect } from "react"
import Layout from '../components/Layout'
import Services from "../components/Services"
import Updates from "../components/Updates"
import Clients from "../components/Clients";
import CaseStudies from "../components/CaseStudies";
import HomepageCarousel from "../components/HomepageCarousel";
import { Container} from "react-bootstrap"; 
import Testimonials from "../components/Testimonials";
import { Seo } from "../components/Seo";
import metaImageOg from '../assets/images/og-image.jpg';
import '../assets/scss/main.scss';
import TermsBanner from "../components/TermsBanner";
import { useLocation } from "@reach/router"
import BookMeetHomePage from "../components/BookMeetHomePage";
// import { globalHistory } from "@reach/router";
import PartnersLogo from "../components/PartnersLogo";

export default ()=> {
  const pathLocation = useLocation();
  const url = pathLocation.href;

  useEffect(() => {
    window.scrollTo(0, 0);
    const homepageScripts = document.createElement("script");
    homepageScripts.id = "homepageScripts";
    homepageScripts.src = "https://sogody-aldachat-ai.onrender.com/snippet.js";
    homepageScripts.async = true;
    document.body.appendChild(homepageScripts);
    return () => {
      document.body.removeChild(homepageScripts);
    };
  }, []);

  return (
    <Layout>
      <Seo 
        title="Best-in-class Digital Experiences | Engineering & AI solutions | Sogody" 
        image={'https://sogody.com'+metaImageOg} 
        description="Experience seamless digital conversions with Sogody's cutting-edge engineering solutions. Focusing on UX, UI & app improvement to tackle any business challenge."
        url={url}
      /> 
      <HomepageCarousel/>
      <Clients/>
      <Services/>
      {/* <ShopigodyBox/> */}
      <CaseStudies/> 
      <Container>
        <BookMeetHomePage />
      </Container>
      <PartnersLogo />
      {/* <StoreezSpell/> */}
      <Testimonials/>
      <Updates/>
      <TermsBanner/>
    </Layout>

)
  }


